import React from "react";

const WhyUs = () => {
  return (
    <section id="why-us" className="why-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="content">
              <h3>Why Choose Pearl Lifesciences?</h3>
              <p>
                At Pearl Lifesciences, we are more than just a company; we are
                your trusted healthcare partner committed to your well-being.
                When you choose us, you're choosing excellence, reliability, and
                personalized care. Here's why we stand out ?
              </p>
              <div className="text-center">
                <a href="#" className="more-btn">
                  Learn More <i className="bx bx-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 d-flex align-items-stretch">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-receipt"></i>
                    <h4>Quality You Can Trust</h4>
                    <p>
                      We prioritize the quality of every product we offer. Our
                      medicines are sourced from reputable suppliers, ensuring
                      you receive safe and effective treatments.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-cube-alt"></i>
                    <h4>Experienced Pharmacists</h4>
                    <p>
                      Our team of experienced pharmacists is dedicated to
                      providing expert guidance on your medications, answering
                      your questions, and helping you manage your health
                      effectively.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-images"></i>
                    <h4>Convenience</h4>
                    <p>
                      We understand that your time is valuable. That's why we
                      offer online ordering and delivery services, making it
                      easier for you to access your medications when and where
                      you need them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
