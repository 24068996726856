import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import Context from './context';

const Html = () => {
    const context = useContext(Context);

    return (
        <>
        <main id="main">
            <section id="departments" class="departments">
                <div class="container">
                    <div class="section-title">
                        <h2>News</h2>
                    </div>

                    <div class="row gy-4">
                        <div class="col-lg-12">
                            <div class="tab-content">
                                <div class="tab-pane active show">
                                    <div class="row gy-4">
                                        <link
                                            rel="stylesheet"
                                            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
                                            integrity="sha256-mmgLkCYLUQbXn0B1SRqzHar6dCnv9oZFPEC1g1cwlkk="
                                            crossorigin="anonymous"
                                        />
                                        <section class="blog-listing">
                                            <div class="container">
                                                <div class="row align-items-start">
                                                    <div class="col-lg-8 m-15px-tb mx-auto">
                                                        <div class="row">

                                                            {context.state.news?.map((item, index) => (
                                                                <div class="col-sm-6" key={index}>
                                                                    <div class="blog-grid">
                                                                        <div class="blog-img">
                                                                            <div class="date">
                                                                                <span>{item.date.day}</span>
                                                                                <label style={{textTransform: "uppercase"}}>{item.date.month_short_name}</label>
                                                                            </div>
                                                                            <img
                                                                                className="news-img"
                                                                                src={item.featured_image}
                                                                                width={400}
                                                                                height={200}
                                                                                title=""
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="blog-info">
                                                                            <h5>
                                                                                <NavLink to={`/news/${item.id}`}>{item.title}</NavLink>
                                                                            </h5>

                                                                            <div class="btn-bar">
                                                                                <NavLink to={`/news/${item.id}`} class="px-btn-arrow">
                                                                                    <span>Read More</span>
                                                                                    <i class="arrow"></i>
                                                                                </NavLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                             ))}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        </>
    );
};

export default Html;
