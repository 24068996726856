import { Route, Routes } from "react-router-dom";
import Main from "./main";
import ProductsList from "./productsList";
import Product from "./product";
import NewsList from "./newsList";
import News from "./news";

import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/main" element={<Main />} />
      <Route path="/products-list" element={<ProductsList />} />
      <Route path="/product/:p_id" element={<Product />} />
      <Route path="/news-list" element={<NewsList />} />
      <Route path="/news/:p_id" element={<News />} />
    </Routes>
  );
}

export default App;
