import React from "react";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a> */}
          </div>

          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>About</h3>
            <p>
              Pearl Lifesciences Pvt Ltd is a dynamic, reliable pharmaceutical wholesale distributor that imports a wide range of healthcare products from renowned manufacturers in the world and  wholesale distributors of ISO and GMP certified European standard product manufacturers in Sri Lanka.
              <br/>
              <br/>
              At Pearl Lifesciences, our commitment to your health is at the heart of everything we do. As a trusted name in pharmaceutical care, we have been serving our community with dedication and compassion since 2017. Our journey has been marked by a relentless pursuit of excellence in healthcare services, and our mission is to make a positive impact on the lives of those we serve.
            </p>

            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-fingerprint"></i>
              </div>
              <h4 className="title">
                <a href="">Our Mission</a>
              </h4>
              <p className="description">
                Our mission is simple yet profound: to provide high-quality
                pharmaceutical products, expert guidance, and exceptional
                customer service to enhance the health and well-being of our
                customers. We believe that every individual deserves access to
                safe, effective, and affordable healthcare solutions.
              </p>
            </div>

            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-gift"></i>
              </div>
              <h4 className="title">
                <a href="">Our Services</a>
              </h4>
              <p className="description">
              Pearl Lifesciences is a leading wholesale distributor of ISO and GMP certified European standard product manufacturers in Bangladesh, India and Sri Lanka, ensuring that you have access to the highest quality pharmaceuticals available.
              </p>
            </div>

            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-atom"></i>
              </div>
              <h4 className="title">
                <a href="">Join Us in Your Health Journey</a>
              </h4>
              <p className="description">
                We are grateful for the trust you place in us, and we look
                forward to being your partner in health. Whether you're managing
                a chronic condition, seeking advice on a new medication, or
                simply looking for ways to lead a healthier life, Pearl
                Lifesciences is here for you. Together, we can achieve better
                health and a brighter future.
              </p>
            </div>

            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-box"></i>
              </div>
              <h4 className="title">
                <a href="">Distribution</a>
              </h4>
              <p className="description">
              We have a seamless distribution system directly through our dedicated expert company logistic team and via Island wide distributor chain.
              Our aim is to ensure delivery of quality effective medicine to patients, doctors, pharmacies and hospitals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
