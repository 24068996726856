export default function reducer(prevState, action) {
  switch (action.type) {
    case "IS_LOADING":
      return {
        ...prevState,
        is_loading: true,
      };
    case "IS_LOADED":
      return {
        ...prevState,
        is_loading: false,
      };
    case "SET_DRUGS_LIST":
      return {
        ...prevState,
        is_loading: false,
        drugs_list: action.drugs_list,
      };
    default:
      throw new Error();
  }
}
