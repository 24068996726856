import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import Context from "../context";

const Gallery = () => {
  const context = useContext(Context);

  return (
    <section id="gallery" className="gallery">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>
      </div>

      <div className="container-fluid">
        <div className="row g-0">
          {context.state.gallery_images?.map((item, index) => (
            <div className="col-lg-3 col-md-4" key={index}>
              <div className="gallery-item">
                <NavLink to={`/product/${item.id}`} className="galelry-lightbox">
                  <img src={item.featured_image} alt="" className="img-fluid" />
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-3">
        <div className="section-title">
          <NavLink to="/products-list">View All Products</NavLink>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
