export default function reducer(prevState, action) {
  switch (action.type) {
    case "IS_LOADING":
      return {
        ...prevState,
        is_loading: true,
      };
    case "IS_LOADED":
      return {
        ...prevState,
        is_loading: false,
      };
    case "SET_NEWS_LIST":
      return {
        ...prevState,
        is_loading: false,
        news: action.news,
      };
    default:
      throw new Error();
  }
}
