import React, {useContext} from "react";

import Context from './context';

const Html = () => {
    const context = useContext(Context);

    return (
        <>
            <main id="main">
                <section id="departments" class="departments">
                    <div class="container">

                        <div class="section-title">
                            <h2>{context.state.news.name}</h2>
                        </div>

                        <div class="row gy-4">
                            <div class="col-lg-12">
                                <div class="tab-content">
                                    <div class="tab-pane active show">
                                        <div class="row gy-4">
                                            <div class="col-lg-8 details order-2 order-lg-1">
                                                <h3>{context.state.news.title}</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: context.state.news.content }}
                                                />
                                            </div>
                                            <div class="col-lg-4 text-center order-1 order-lg-2">
                                                <img src={context.state.news.featured_image} alt="" class="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
};

export default Html;
