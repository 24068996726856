import axios from "axios";

export const getGalleryImages = (dispatch) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_DOMAIN+process.env.REACT_APP_GALLERY_IMAGE_ENDPOINT,
        headers: { }
    };
    
    axios.request(config).then((response) => {
        console.log(response);
        if(response.status === 200) {
            dispatch({
                type: "SET_GALLERY_IMAGES",
                gallery_images: response.data.gallery_images
            });
        }
    }).catch((error) => {
        console.log(error);
    });
}

export const sendEmail = (data, reset, dispatch) => {
    dispatch({type: 'SENDING_EMAIL'});
    
    let contact_data = JSON.stringify({
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message
    });
      
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_DOMAIN+process.env.REACT_APP_CONTACT_US,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : contact_data
    };
      
    axios.request(config).then((response) => {
        console.log(response);
        if(response.status === 200) {
            reset();
            dispatch({
                type: 'SENDED_EMAIL',
                res_type: "success"
            });
        }else{
            dispatch({
                type: 'SENDED_EMAIL',
                res_type: "failure"
            });
        }
    }).catch((error) => {
        console.log(error);
    });
};