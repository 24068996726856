import axios from "axios";

export const getDrugData = (p_id, dispatch) => {
    console.log("🚀 ~ file: services.js:4 ~ getDrugData ~ p_id:", p_id)
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_DOMAIN+process.env.REACT_APP_DRUG+'?id='+p_id,
        headers: { }
    };
    
    axios.request(config).then((response) => {
        console.log(response);
        if(response.status === 200) {
            dispatch({
                type: "SET_DRUG",
                drug: response.data.drug
            });
        }
    }).catch((error) => {
        console.log(error);
    });
}