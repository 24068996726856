import React, { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <NavLink to="/" className="logo me-auto">
          <img src="/assets/img/logo-bgr.png" alt="" className="img-fluid" />{" "}
          {windowSize.current[0] > 500 ? "Pearl Lifesciences" : null}
        </NavLink>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              {currentPath === "/" ? (
                <a href="#hero" className="nav-link scrollto ">
                  Home
                </a>
              ) : (
                <NavLink to="/" className="nav-link scrollto">
                  Home
                </NavLink>
              )}
              </li>
              
            {currentPath === "/" ? (
              <>
                <li>
                  <a className="nav-link scrollto" href="#about">
                    About
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#faq">
                    Faq
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#gallery">
                    Gallery
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#certificate">
                    Certificate
                  </a>
                </li>
              </>
            ) : null}

              <li>
                <NavLink to="/products-list" className="nav-link scrollto">
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink to="/news-list" className="nav-link scrollto">
                  News
                </NavLink>
              </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>

        {currentPath === "/" ? (
          <a href="#contact" className="appointment-btn scrollto">
            <span className="d-none d-md-inline">Contact</span> Us
          </a>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
