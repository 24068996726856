import React, { useRef } from "react";

const Hero = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container" style={{paddingTop: windowSize.current[0] > 500 ? '' : '100px'}}>
        {/* {windowSize.current[0] > 1400 ? ( */}
        <h1>Pearl Lifesciences <br /> Pvt Ltd</h1>
        {/* ) : null} */}
        <h2>
        Pearl Lifesciences Pvt Ltd is a dynamic,<br /> reliable pharmaceutical <br /> company that imports a wide range of <br /> healthcare products from renowned <br /> manufacturers in the world and wholesale <br /> distributors of ISO and GMP certified <br /> European standard product manufacturers <br /> in Sri Lanka.
        </h2>
        {/* <a href="#about" className="btn-get-started scrollto">
          Get Started
        </a> */}
      </div>
    </section>
  );
};

export default Hero;
