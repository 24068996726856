import React from "react";

import Hero from "./components/hero";
import WhyUs from "./components/whyUs";
import About from "./components/about";
import FAQ from "./components/faq";
import Gallery from "./components/gallery";
import ContactUs from "./components/contactUs";
import Certificate from "./components/certificate";
import Slider from "./components/slider";

const Html = () => {
    return(
        <>
            <Hero/>
            <main id="main">
                <WhyUs/>
                <About/>
                <FAQ/>
                <Certificate/>
                <Slider/>
                <Gallery/>
                <ContactUs/>
            </main>
        </>
    );
}

export default Html;