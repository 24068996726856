import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>Pearl Lifesciences</h3>
              <p>
                577 <br />
                Henihatta, Biyagama - 11650
                <br />
                Sri Lanka <br />
                <br />
                <strong>Phone:</strong> (+94) 114 402 611
                <br />
                <strong>Email:</strong> pearl@pearllifesciences.com
                <br />
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  {currentPath === "/" ? (
                    <a className="scrollto" href="#hero">
                      Home
                    </a>
                  ) : (
                    <NavLink to="/" className="scrollto">
                      Home
                    </NavLink>
                  )}
                </li>
                {currentPath === "/" ? (
                <>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a className="scrollto " href="#about">
                    About{" "}
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a className="scrollto " href="#faq">
                    Faq
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a className="scrollto " href="#gallery">
                    Gallery
                  </a>
                </li>
                {/* <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a className="scrollto " href="#hero">
                    Privacy policy
                  </a>
                </li> */}
                </>
                ) : null }
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <NavLink to="/news-list" className="scrollto ">
                    News{" "}
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Products</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <NavLink to="/products-list">products</NavLink>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Join Our Newsletter</h4>
                            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                            <form action="" method="post">
                                <input type="email" name="email" />
                                <input type="submit" value="Subscribe" />
                            </form>
                        </div> */}
          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Pearl Lifesciences</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="https://www.proxmarketers.com/">ProXmarketers</a>
          </div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="https://twitter.com/PearlLifes2017" className="twitter" target="_blank">
            <i className="bx bxl-twitter"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61552473124000" className="facebook" target="_blank">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="https://www.instagram.com/pearllifesciences_2017/" className="instagram" target="_blank">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="http://www.linkedin.com/in/pearl-lifesciences-580133297" className="linkedin" target="_blank">
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
