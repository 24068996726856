import React from 'react';

import Header from './header';
import Topbar from './topbar';
import Breadcrumbs from './breadcrumbs';
import Footer from './footer';

const Template = ({children}) => {
    return(
        <>
            <Topbar/>
            <Header/>
            {children}
            <Footer/>
        </>
    );
}

export default Template;