import React from "react";

const TopBar = () => {
  return (
    <div id="topbar" className="d-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-envelope"></i>{" "}
          <a href="mailto:pearl@pearllifesciences.com">
            pearl@pearllifesciences.com
          </a>
          <i className="bi bi-phone"></i> (+94) 114 402 611
        </div>
        <div className="d-none d-lg-flex social-links align-items-center">
          <a href="https://twitter.com/PearlLifes2017" className="twitter" target="_blank">
            <i className="bi bi-twitter"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61552473124000" className="facebook" target="_blank">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="https://www.instagram.com/pearllifesciences_2017/" className="instagram" target="_blank">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="http://www.linkedin.com/in/pearl-lifesciences-580133297" className="linkedin" target="_blank">
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
