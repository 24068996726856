import React from "react";
import { NavLink } from "react-router-dom";

import duralife from "../img/duralife.png"
import oplee from "../img/Oplee Loss Zero & Oplee Dan Zero.png"
import vibe from "../img/vibe.png"
import vitane from "../img/vitane.png"

const Slider = () => {
  return (
    <section id="slider" class="testimonials">
        <div className="container">
          <div className="section-title">
            <h2>News</h2>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <div class="card">
                <NavLink to={`/news/243`}><img class="card-img-top" src={duralife} alt="duralife"/></NavLink>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card">
                <NavLink to={`/news/258`}><img class="card-img-top" src={vitane} alt="vitane"/></NavLink>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card">
                <NavLink to={`/news/240`}><img class="card-img-top" src={vibe} alt="vibe"/></NavLink>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card">
                <NavLink to={`/news/157`}><img class="card-img-top" src={oplee} alt="oplee"/></NavLink>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};

export default Slider;