import React, {useContext, useState} from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Context from './context';

const Html = () => {
    const [index, setIndex] = useState(-1);
    const context = useContext(Context);

    const handleClick = (index) => setIndex(index);

    const slides = context.state.drug?.gallery?.map(
        ({ original, width, height }) => ({
            src: original,
            width,
            height,
        })
    );

    return (
        <>
            <main id="main">
                <section id="departments" class="departments">
                    <div class="container">

                        <div class="section-title">
                            <h2>{context.state.drug.name}</h2>
                        </div>

                        <div class="row gy-4">
                            <div class="col-lg-12">
                                <div class="tab-content">
                                    <div class="tab-pane active show">
                                        <div class="row gy-4">
                                            <div class="col-lg-8 details order-2 order-lg-1">
                                                <h3 style={{marginBottom: '40px'}}>{context.state.drug.title.replace(/<br\/>/g, ",")}</h3>
                                                {context.state.drug.leaflet.length > 0 ? (
                                                    <>
                                                        {context?.state?.drug?.leaflet?.map((item, index_leaflet) => (
                                                            <div class="image-container">
                                                                <li key={index_leaflet}><img src={item.src} alt="Your Image"/></li>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : null}
                                                <div dangerouslySetInnerHTML={{ __html: context.state.drug.content }} />
                                            </div>
                                            <div class="col-lg-4 text-center order-1 order-lg-2">
                                                <ul class="image-list">
                                                    <li><img src={context.state.drug.featured_image} alt="Image 1"/></li>
                                                    {context?.state?.drug?.gallery?.map((item, index) => (
                                                        <li key={index}><img src={item.src} alt="Image 1"/></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
};

export default Html;
