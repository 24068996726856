import React, { useEffect, useReducer } from 'react';
import {useParams   } from "react-router-dom";

import Html from './html';
import Context from './context';
import Reducer from './reducer';
import {getDrugData} from './services';

import Template from '../components/template';
import Loader from '../components/loader';

const Product = () => {
    let { p_id } = useParams();
    console.log("🚀 ~ file: index.js:14 ~ Product ~ p_id:", p_id)

    const [state, dispatch] = useReducer(Reducer,{
        is_loading: true,
        drug: {},
    });

    useEffect(() => {
        getDrugData(p_id, dispatch);
    },[]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Template>
                {state.is_loading ? <Loader/> : <Html/>}
            </Template>
        </Context.Provider>
    );
}

export default Product;