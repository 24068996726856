import React, { useEffect, useReducer } from 'react';

import Html from './html';
import {getGalleryImages} from './services';
import Context from './context';
import Reducer from './reducer';

import Template from '../components/template';
import Loader from '../components/loader';

const Main = () => {
    const [state, dispatch] = useReducer(Reducer,{
        is_loading: true,
        gallery_images: [],
        is_sending_email: false,
        show_email_res_type: null,
        show_sent_message: false,
    });

    useEffect(() => {
        getGalleryImages(dispatch);
    },[]);

    return (
        <Context.Provider value={{state, dispatch}}>
            <Template>
                {state.is_loading ? <Loader/> : <Html/>}
            </Template>
        </Context.Provider>
    );
};

export default Main