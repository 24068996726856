import React, { useEffect, useReducer } from 'react';

import Html from './html';
import Context from './context';
import Reducer from './reducer';
import {getDrugsList} from './services';

import Template from '../components/template';
import Loader from '../components/loader';

const ProductsList = () => {
    const [state, dispatch] = useReducer(Reducer,{
        is_loading: true,
        drugs_list: [],
    });

    useEffect(() => {
        getDrugsList(dispatch);
    },[]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Template>
                {state.is_loading ? <Loader/> : <Html/>}
            </Template>
        </Context.Provider>
    );
}

export default ProductsList;