/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useContext} from "react";
import { useForm } from "react-hook-form";

import Context from '../context';
import { sendEmail } from '../services';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const context = useContext(Context);

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
          <p>
            We're here to assist you with your healthcare needs and answer any
            questions you may have. There are several ways to get in touch with
            us.
          </p>
        </div>
      </div>

      <div>
        <iframe
          style={{
            border: "0",
            width: "100%",
            height: "350px",
          }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15841.625684814368!2d80.0021092!3d6.9612905!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae257578a4f4aa5%3A0x8ad08231ec86ff91!2sPearl%20Lifesciences%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1694491341706!5m2!1sen!2slk"
          frameBorder="0"
          allowfullscreen
        ></iframe>
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>577, Henihatta, Biyagama - 11650, Sri Lanka</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>pearl@pearllifesciences.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>(+94) 114 402 611</p>
              </div>
            </div>
          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
            <form onSubmit={handleSubmit((data) => sendEmail(data, reset, context.dispatch))} className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    {...register("name", {
                        required: true,
                    })}
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    {...register("email", {
                        required: true,
                    })}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  {...register("subject", {
                    required: true,
                  })}
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  placeholder="Message"
                  {...register("message", {
                    required: true,
                  })}
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message" style={{display: context.state.show_email_res_type === "failure" ? "" : "none"}}></div>
                <div className="sent-message" style={{display: context.state.show_email_res_type === "success" ? "" : "none"}}>
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit" disabled={!context.state.is_sending_email}>
                  {!context.state.is_sending_email ? (
                    <>Send Message</>
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
