export default function reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED":
            return {
                ...prevState,
                is_loading: false,
            };
        case "SET_GALLERY_IMAGES":
            return {
                ...prevState,
                is_loading: false,
                gallery_images: action.gallery_images
            };
        case "SENDING_EMAIL":
            return {
                ...prevState,
                is_sending_email: true,
            }; 
        case "SENDED_EMAIL":
            return {
                ...prevState,
                show_email_res_type: action.res_type,
                is_sending_email: false,
            };
        default:
            throw new Error();
    }
}