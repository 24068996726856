import React, {useContext} from "react";
import { NavLink } from "react-router-dom";

import Context from './context';

const Html = () => {
    const context = useContext(Context);

    return (
        <>
            <main id="main">
                <section id="doctors" className="doctors">
                    <div className="container">
                        <div className="section-title">
                            <h2>Products List</h2>
                            <p>Find the Right Prescription and Over-the-Counter Products Here.</p>
                        </div>

                        <div className="row">
                            {context.state.drugs_list.map((drug, index) => (
                                <div className="col-lg-6" key={index}>
                                    <div className="member d-flex align-items-start">
                                        <div className="pic">
                                            <NavLink to={`/product/${drug.id}`}>
                                                <img
                                                    src={drug.featured_image}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </NavLink>
                                        </div>
                                        <div className="member-info" style={{paddingTop: '5%'}}>
                                            <NavLink to={`/product/${drug.id}`}>
                                                <h4 style={{ textTransform: "uppercase" }}>{drug.name}</h4>
                                            </NavLink>
                                            <span dangerouslySetInnerHTML={{ __html: drug.title }} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Html;
