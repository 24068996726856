import React, { useEffect, useReducer } from 'react';
import {useParams   } from "react-router-dom";

import Html from './html';
import Context from './context';
import Reducer from './reducer';
import {getNewsList} from './services';

import Template from '../components/template';
import Loader from '../components/loader';

const ProductsList = () => {
    let { p_id } = useParams();

    const [state, dispatch] = useReducer(Reducer,{
        is_loading: true,
        news: [],
    });

    useEffect(() => {
        getNewsList(p_id, dispatch);
    },[]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Template>
                {state.is_loading ? <Loader/> : <Html/>}
            </Template>
        </Context.Provider>
    );
}

export default ProductsList;