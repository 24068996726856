import React from "react";

const Certificate = () => {
  return (
    <section id="certificate" class="testimonials">
        <div class="container">
            <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="testimonial-wrap">
                            <div class="testimonial-item">
                                <img src="assets/img/cos.png" class="testimonial-img" alt=""/>
                                <h3><span style={{color: '#0032a0'}}>Certificate</span> <span style={{color: '#0082cb'}}>of</span> <span style={{color: '#8d9293'}}>suitability</span></h3>
                                <p>
                                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                    <h3>What is the certificate of suitability?</h3>
                                    The role of a Certificate of Suitability (CEP) is to certify the compliance of a material with the requirements laid down in the relevant monograph of the European Pharmacopoeia. Active pharmaceutical ingredients for which a Certificate of Suitability has been granted are suitable for use in medicinal products.
                                    <br/><br/>
                                    <h3>What is a CEP certification?</h3>
                                    A certified equity professional, or CEP, has passed a series of three exams developed at the Certified Equity Professional Institute, CEPI, affiliated with the Leavey School of Business at Santa Clara University, demonstrating her expertise in the field of equity compensation.
                                    <br/><br/>
                                    A manufacturer of a substance can provide proof that the quality of the substance is suitably controlled by the relevant monographs of the European Pharmacopoeia by a CEP granted by the Certification Secretariat of the European Directorate for the Quality of Medicines (EDQM). The CEP confirms that pharmaceutical substances or active pharmaceutical ingredients (API) are produced according to the monographs of the EP. 
                                    <br/><br/>
                                    <p className="mission-text">Liplo10, Liplo20, LOK-50 and Syrup Deslona  are manufactured according to the Certificate of Suitability.</p>

                                    {/* <img src="assets/img/Screenshot 2023-09-21 031532.png" class="testimonial-img" alt=""/> */}
                                    {/* <br/><br/> */}
                                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </section>
  );
};

export default Certificate;