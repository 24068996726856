export default function reducer(prevState, action) {
  switch (action.type) {
    case "IS_LOADING":
      return {
        ...prevState,
        is_loading: true,
      };
    case "IS_LOADED":
      return {
        ...prevState,
        is_loading: false,
      };
    case "SET_DRUG":
      return {
        ...prevState,
        is_loading: false,
        drug: action.drug,
      };
    default:
      throw new Error();
  }
}
